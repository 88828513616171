@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page_heading {
    height: 98px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #f8fcfe; */
    margin: 50px 0;
    
  }
  
  h1 {
    
    font-weight: 700 !important;
}

.shortcut_nav{

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.short_btn{
  border-color: #ffffff;
  border: 1px solid #48ad02;
  background-color:#40AE49;
  color: #ffffff;
  font-size: 22px;
  padding: 8px 15px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.progress-container {
  width: 100%;
  height: 8px;
  background: #ccc;
}

.progress-bar {
  height: 8px;
  background: #04aa6d;
  width: 0%;
}

.content {
  padding: 100px 0;
  margin: 50px auto 0 auto;
  width: 80%;
}

.splash {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: #f1f2f3;
  place-items: center;
  text-align: center;
  justify-content: center;
  overflow: hidden;
}

.rightText {
  text-align: right !important;
}

.CenterContect {
  margin: auto;
}

.WelcomePageTitle {
  margin-bottom: 0px !important;
}

.Multi {
  font-size: 22px;
}

.mainatractContect a {
  cursor: pointer;
}

.StatisticCube {
  margin-bottom: 20px;

}

.StatisticCube2 {
  margin-bottom: 20px;
  height: 320px;

}

.NumToRemoveStock {
  width: 180px !important;
}

.SelectHourS {
  width: 180px !important;
}

.OrderTable ul {
  text-align: right;
}

.hidden {
  display: none !important;
}

.UploadImage {
  width: 250px;
  height: 40px;
  background: #40AE49 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
    cursor: pointer;
  white-space: nowrap;

}

.ticketImage::after{
  content: "";
    display: block;
    height: 100%;
    width: 0;
    margin: 0 0 0 10px;
    position: absolute;
    top: 0;
    left: -10px;
    border-left: 5px dashed #fff;
}

.downloadincvoice {
  height: 62px;
  cursor: pointer;
}

.StatisticDats {
  width: 400px;
  display: block;
  margin: auto;
}

.App {
  direction: rtl !important;
  font-family: 'Open Sans Hebrew', serif !important;
  font-size: 16px;
}

.boldli {
  font-weight: 500 !important;
  font-size: 17px;
  white-space: nowrap;

}

.App-header img {

  margin: 0 auto;

  -webkit-transform: translate(10px, -8px);

          transform: translate(10px, -8px);

}

.CouponMID {
  width: 300px !important;
  margin: auto;

}

.contect {

  justify-content: center;
  align-items: center;
  text-align: center;

}

.contect h1 {

  font-weight: 800;

}

svg {
  margin-left: 5px;
}

.StatisticCube {
  min-height: 130px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #48ad02;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  text-align: center;
  padding: 15px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 190px;
  width: 95%;
  margin: auto;
cursor: pointer;
transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.StatisticCube:hover {
  border-color: #ffffff;
  color: #ffffff;
  background-color: #48ad02;
}

.StatisticCube:hover h4 {
  border-color: #ffffff;
  color: #ffffff;
}

.StatisticCube:hover span {
  border-color: #ffffff;
  color: #ffffff;
}
.StatisticCube2 {
  min-height: 130px;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  text-align: center;
  padding: 35px;
  margin-top: 35px;

}

.App-header {
  background: #F7FCFE 0% 0% no-repeat padding-box;
  padding: 5px 0px 5px 0px;
  color: black;
  font-size: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.active {
  text-decoration: underline !important;
}
.stats {
  grid-gap: 0.333rem;
  gap: 0.333rem;
  margin: 0 auto;
  padding: 1rem 0.5rem 0 0;
  border-radius: 50px;
  min-width: 35vw ;
  text-align: center;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  font-size: 1rem;
}

[type=email],
[type=number],
[type=tel],
[type=url] {
  direction: rtl !important;
}

.table-striped > tbody > tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #b9e5fa1c !important;
  color: var(--bs-table-striped-color) !important;
  cursor: pointer;
}
nav {
  position: fixed;
}

#stickylogo {
  position: absolute;
  bottom: 60px;
  left: 15px;
}

.logincenter {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90vh;
}

.logincenter .btn {
  background: #40AE49 0% 0% no-repeat padding-box;
  border-color: white !important;
  border-radius: 4px;

}

.logincenter .btn:hover {
  background: #40AE49 0% 0% no-repeat padding-box;
}



a {
  color: inherit !important;
  text-decoration: none !important;
}

.sidebar ul {

  list-style-type: none;

}

.sidebar ul li {
  padding: 10px 0 10px 0;

}

.sidebar {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  right: 0;
  min-height: 100vh !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  top: 0;

}


.controllogin {
  width: 320px;
  margin-top: 20px;

}

.StatisticCube h4 {
  color: black;
  font-size: 17px;


}

.StatisticCube2 h4 {
  color: black;
  font-size: 17px;

}

.StatisticCube span {
  color: #40AE49;
  font-size: 40px;
  font-weight: 800;
  margin-top: -10px;
}

.StatisticCube2 span {
  color: #40AE49;
  font-size: 30px;
  font-weight: 800;


}

.insideloginform {
  display: block;
  margin: 0 auto;
}

.react-datepicker-popper {
  inset: auto !important;
  -webkit-transform: translate(0px, 0px) !important;
          transform: translate(0px, 0px) !important;


}

/* main atract */

.h1mainatract {
  margin-top: 45px;
  font-size: 20px;
  font-weight: 800;
  display: inline-flex;
}


.h1mainatract:before {
  content: '';
  display: block;
  height: 25px;
  width: 4px;
  background-color: #40AE49;
  margin: 0 0 0 7px;
  border-radius: 3px;

}

.mainatractContect h2 {
  text-align: center;
  font-size: 25px;

}

.mainatractContect {
  width: 75%;
  margin: auto;
}

.atractinput1 {
  width: 100%;
  margin-top: 30px;
}

.atractinputOpenTime {
  margin-top: 40px;


}

.page-item.active .page-link {
  background-color: #40AE49 !important;
  border-color: #40AE49 !important;

}

.openHoursh2 {
  position: absolute !important;

}

.addTypeBTN {
  cursor: pointer;
}

.Pointer {
  cursor: pointer;
}

.VisitorTypeDIV {
  text-align: center;
  margin-top: 10px;
  height: 40px;
  border-radius: 4px;
  line-height: 40px;
  font-weight: 600;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #48ad02;
}

.moredataiicone {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.NoResults {
  text-align: center !important;
  font-weight: bold;
  font-size: 26px;
  width: 70vw;
  height: 40vh;
  display: grid;
  place-content: center;
  place-self: center;
  align-self: center;

}

.form-switch .form-check-input {
  width: 40px !important;
  height: 20px !important;
  cursor: pointer !important;

}

.form-check-input:checked {
  background-color: #40AE49 !important;
  border-color: #40AE49 !important;

}

.SelectSort {
  width: 200px;
  margin-top: 20px;
}

.SelectSort h2 {
  font-weight: bold;
  font-size: 25px;
}

.Xbtn {
  padding-top: 15px;
  font-weight: 800;
  color: red;
  cursor: pointer;
  font-size: 23px;
}

.SaveAtractbtn {
  width: 300px;
  margin: auto;
  margin-top: 100px;
  font-weight: 900 !important;
  margin-bottom: 50px;

}

.OrderSearch button {
  background-color: #40AE49 !important;
  border-radius: 4px !important;
  color: white !important;
  border-color: white !important;


}

.SaveAtractbtnCoupon {
  width: 100%;
  margin: auto;
  height: 40px;
  margin-top: 10px;

  font-weight: 900 !important;

}

.OrderTable {
  margin-top: 40px;
}

.OrderSearch {
  width: 70% !important;
  display: block;
  margin: auto;
  padding-top: 15px;
}

.TopGrey {
  width: 100%;
  height: 90px;
  background-color: #b9e5fa1c;
  border-radius: 5px;
  padding: 0 1rem 0 1rem;
  display: grid;
  place-items: center;
  border: 1px solid #b9e5faf5;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DelImage {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  margin-top: 30px;
  background-color: white;
  padding: 5px;
}

.SubAtract {
  font-size: 26px;
}

.CouponList {
  margin-top: 50px !important;
}

.SelectSize {
  width: 100%;
}

.ShowMainImage {
  width: 100%;
  height: 250px;
  position: relative;
  object-fit: cover;
}

#formFileMultiple {
  width: 450px;
}

.VisitorTypeDIV1 {
  font-size: 18px;
}

/* main atract end */

.input-group>.form-control,
.input-group>.form-select {
  width: 100% !important;
}


.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: grid;
  width: 100%;
  /* margin: 2px 0 2px 0; */

  height: 37px;
  border-radius: 6px;
  box-shadow: none;

}

.react-datepicker__input-container input:focus {
  display: grid;
  width: 100%;
  /* margin: 2px 0 2px 0; */
  border: #88b1ff 0.12px solid;

  height: 37px;
  border-radius: 6px;
  box-shadow: none;

}

.react-datepicker__input-container input {
  display: grid;
  width: 100%;
  /* margin: 2px 0 2px 0; */
  margin-right: 5px;
  height: 37px;
  border: #8080804f 0.12px solid;
  border-radius: 6px;
  box-shadow: none;
}

